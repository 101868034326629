<!-- eslint-disable prefer-const -->
<template>
  <div>
    <b-card>
      <div class="text-right mb-3">
        <b-select
          v-model="perPage"
          :options="pageOptions"
          style="width: fit-content"
        />
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucun utilisateur enrégistré"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
          <div class="d-flex justify-content-center">
            <strong>Chargement en cours...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template v-slot:cell(roles)="data">
          <ul>
            <li
              v-for="(item, index) in data.item.roles"
              :key="index"
            >
              {{ handleCurrentPrivilege(item) }}
            </li>
          </ul>
        </template>
        <template v-slot:cell(action)="row">
          <span class="whitespace-no-wrap">
            <feather-icon
              size="18"
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
              svg-classes="w-5 h-5 stroke-current"
              class="text-info"
              @click="row.toggleDetails"
            />
          </span>
          <span class="whitespace-no-wrap ml-1">
            <feather-icon
              size="18"
              icon="EditIcon"
              svg-classes="w-8 h-5 stroke-current"
              class="text-warning"
              @click="
                (e) => {
                  e.preventDefault();
                  openUpdateModal(row.item);
                }
              "
            />
          </span>
          <span class="whitespace-no-wrap ml-1">
            <feather-icon
              size="18"
              icon="TrashIcon"
              svg-classes="w-8 h-5 stroke-current"
              class="text-danger"
              @click="
                (e) => {
                  e.preventDefault();
                  handleDeleteModal(row.item);
                }
              "
            />
          </span>
        </template>
        <template #row-details="row">
          <b-row>
            <b-col sm="6">
              <b-card title="Détails">
                <table>
                  <tr class="w-100">
                    <td class="w-50">
                      Email
                    </td>
                    <td class="w-50">
                      {{ row.item.email }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Téléphone
                    </td>
                    <td class="w-50">
                      {{ row.item.phone_number }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Adresse
                    </td>
                    <td class="w-50">
                      {{ row.item.address }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Profession
                    </td>
                    <td class="w-50">
                      {{ row.item.profession }}
                    </td>
                  </tr>
                  <tr>
                    <td class="w-50">
                      Status
                    </td>
                    <td class="w-50">
                      {{ row.item.active ? "Actif" : "Inactif" }}
                    </td>
                  </tr>
                </table>
              </b-card>
            </b-col>
          </b-row>
        </template>
      </b-table>

      <div class="mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </b-card>

    <b-modal
      v-model="showDeleteModal"
      :visible="showDeleteModal"
      title="Suppression du compte utilisateur"
      centered
    >
      <div>
        <p>
          Vous êtes sur le point de supprimer cet utilisateur. Voulez vous
          continuer ?
        </p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            size="sm"
            class="float-left"
            @click="handleDeleteModal()"
          >
            Annuler
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            class="float-right"
            @click="tryToDeleteUser"
          >
            <b-spinner
              v-if="deleteProcess"
              small
            />
            Supprimer
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      v-if="showUpdateModal"
      v-model="showUpdateModal"
      title="Modification du profil utilisateur"
      centered
      size="lg"
      no-close-on-backdrop
      hide-footer
      no-close-on-esc
      @cancel="onCloseModal"
      @close="onCloseModal"
    >
      <form-wizard
        ref="registerWizard"
        step-size="xs"
        :hide-buttons="true"
        color="#006435"
        title=""
        subtitle=""
      >
        <tab-content title="Informations Personnelles">
          <b-form>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Nom">
                  <b-form-input
                    v-model.trim="$v.user.lastname.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.lastname.$error,
                    }"
                  />
                  <div
                    v-if="$v.user.lastname.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.lastname.required">Nom requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Prénom (s)">
                  <b-form-input
                    v-model.trim="$v.user.firstname.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.firstname.$error,
                    }"
                  />
                  <div
                    v-if="$v.user.firstname.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.firstname.required"
                    >Prénom (s) requis.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Email">
                  <b-form-input
                    v-model.trim="$v.user.email.$model"
                    :class="{
                      'is-invalid': $v.user.email.$error,
                    }"
                    :formatter="lower"
                    readonly
                  />
                  <div
                    v-if="$v.user.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.user.email.required">Email requis.</span>
                    <span
                      v-if="!$v.user.email.email"
                    >Entrer un email valide.</span>
                  </div>
                  <div v-if="emailExist">
                    <small class="sm text-danger">Email déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Téléphone">
                  <vue-phone-number-input
                    v-model.trim.trim="phone"
                    name="register-phone"
                    size="sm"
                    valid-color="#006435"
                    :dark="dark"
                    dark-color="transparent"
                    error-color="red"
                    :translations="translate"
                    color="#006435"
                    :fetch-country="true"
                    :only-countries="['BJ']"
                    :border-radius="4"
                    :required="true"
                    @update="update($event)"
                  />
                  <div v-if="phoneExist">
                    <small
                      class="sm text-danger"
                    >Numéro de téléphone déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Adresse">
                  <b-form-input
                    v-model.trim="$v.user.address.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.address.$error,
                    }"
                  />
                  <div
                    v-if="$v.user.address.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.address.required"
                    >Adresse requise.</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Profession">
                  <b-form-input
                    v-model.trim="$v.user.profession.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.profession.$error,
                    }"
                  />
                  <div
                    v-if="$v.user.profession.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.profession.required"
                    >Profession requise.</span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  @click="stepOne"
                >
                  Suivant
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
        <tab-content title="Détails du compte">
          <b-form>
            <b-row>
              <b-col sm="6">
                <b-form-group label="Nom d'utilisateur">
                  <b-form-input
                    v-model.trim="$v.user.username.$model"
                    :class="{
                      'is-invalid': $v.user.username.$error,
                    }"
                    readonly
                  />
                  <div
                    v-if="$v.user.username.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.username.required"
                    >Nom d'utilisateur requis</span>
                    <span
                      v-if="!$v.user.username.alphaNum"
                    >Le nom d'utilisateur peut contenir des chiffres et des
                      lettres.
                    </span>
                  </div>
                  <div v-if="userExist">
                    <small
                      class="sm text-danger"
                    >ELe nom d'utilisateur existe déjà utilisé.</small>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group label="Droits utilisateurs">
                  <v-select
                    v-model="$v.user.roles.$model"
                    :options="userRoles"
                    multiple
                    :class="{
                      'is-invalid': $v.user.roles.$error,
                    }"
                  />
                  <div
                    v-if="$v.user.roles.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.roles.required"
                    >Nom d'utilisateur requis</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Matricule">
                  <b-form-input
                    v-model.trim="$v.user.matricule.$model"
                    :formatter="formatter"
                    :class="{
                      'is-invalid': $v.user.matricule.$error,
                    }"
                    readonly
                  />
                  <div
                    v-if="$v.user.matricule.$error"
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.user.matricule.required"
                    >Le numéro matricule est requis</span>
                  </div>
                </b-form-group>
              </b-col>

              <b-col sm="6">
                <b-form-group label="Mot de passe">
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      v-model="user.password"
                      :type="passwordFieldType"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="KeyIcon"
                        @click="generatePassword"
                      />
                      <feather-icon
                        class="cursor-pointer ml-1"
                        icon="CopyIcon"
                        @click="copyPassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <b-button
                  variant="outline-primary"
                  class="float-left"
                  :disabled="process"
                  @click="$refs.registerWizard.prevTab()"
                >
                  Précédent
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="float-right"
                  :disabled="process"
                  @click="finalStep"
                >
                  <b-spinner
                    v-if="process"
                    small
                  />
                  <span v-show="!process">Enrégister</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </tab-content>
      </form-wizard>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import 'vue-select/dist/vue-select.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { required, email, alphaNum } from 'vuelidate/lib/validators'

export default {
  components: {
    // BSV
    FormWizard,
    TabContent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      items: [],
      loaded: false,
      totalItems: null,
      showDeleteModal: false,
      showUpdateModal: false,
      fields: [
        { key: 'index', label: 'N°' },
        { key: 'username', label: 'username' },
        { key: 'matricule', label: 'Matricule' },
        { key: 'last_name', label: 'Nom' },
        { key: 'first_name', label: 'Prénom(s)' },
        { key: 'roles', label: 'Privilege' },
        { key: 'action', label: 'Action', class: 'text-center' },
      ],
      currentPage: 1,
      currentUser: {},
      perPage: 5,
      pageOptions: [5, 15, 20],
      phone: this.currentUser?.phone_number?.substring(3),
      emailExist: null,
      phoneExist: null,
      userExist: null,
      isValid: false,
      userRoles: [
        { label: 'Prévision Navire', value: 'ROLE_PREVISION_NAVIRE' },
        { label: 'Actualités et Offres', value: 'ROLE_DIR_COM' },
        { label: 'Cotation', value: 'ROLE_OPERATION' },
        { label: 'Administrateur', value: 'ROLE_ADMIN' },
        { label: 'Super Admin', value: 'ROLE_SUPER_ADMIN' },
      ],
      user: {
        username: this.currentUser?.username,
        phoneNumber: this.currentUser?.phone_number,
        email: this.currentUser?.username,
        roles: this.currentUser?.roles,
        lastname: this.currentUser?.last_name,
        firstname: this.currentUser?.first_name,
        address: this.currentUser?.address,
        matricule: this.currentUser?.matricule,
        profession: this.currentUser?.profession,
        password: null,
        profileImage: '',
        callBackUrl: `${window.location.hostname}/confirm-account`,
      },
      translate: {
        countrySelectorLabel: 'Code pays',
        countrySelectorError: 'Choisir un pays',
        phoneNumberLabel: 'Numéro de téléphone',
        example: 'Exemple :',
      },
    }
  },
  validations: {
    user: {
      username: {
        required,
        alphaNum,
      },
      phoneNumber: {
        required,
      },
      email: {
        required,
        email,
      },
      roles: {
        required,
      },
      lastname: {
        required,
      },
      firstname: {
        required,
      },
      address: {
        required,
      },
      matricule: {
        required,
      },
      profession: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      token: 'token',
      success: 'registerSuccess',
      process: 'registerProcess',
      error: 'registerError',
      deleteError: 'deleteError',
      deleteSuccess: 'deleteSuccess',
      deleteProcess: 'deleteProcess',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    dark() {
      if (window.localStorage.getItem('vuexy-skin') === 'dark') return true
      return false
    },
  },
  watch: {
    currentPage() {
      this.getAllUsers()
    },
    perPage() {
      this.getAllUsers()
    },
    success(val) {
      if (val) {
        switch (val.valid) {
          case 0:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            window.location.reload()
            break
          default:
            this.reset()
            this.closeUpdateModal()
            this.getAllUsers()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Modification',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    error(val) {
      if (val.code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Enrégistrement',
            text: `Une erreur est survenue lors de l'enregistrement. Code ${val.code}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    deleteSuccess(val) {
      if (val) {
        switch (val.valid) {
          case 0:
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Suppression',
                text: val.message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
            break
          default:
            this.reset()
            this.getAllUsers()
            this.handleDeleteModal()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Enrégistrement',
                text: val.message,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            break
        }
      }
    },
    deleteError(val) {
      if (val.code) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression',
            text: `Une erreur est survenue lors de la suppression du compte utilisateur. Code ${val.code}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  mounted() {
    this.getAllUsers()
  },
  methods: {
    ...mapActions(['updateUser', 'deleteUser']),
    generatePassword() {
      const chars = "0123456789abcdefghijklmnopqrstuvwxyz!#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]ABCDEFGHIJKLMNOPQRSTUVWXYZ"
      let password = ''

      const lengthRegex = /^.*(?=.{8,}).*$/
      const upper = /^(?=.*?[A-Z])/
      const lower = /^(?=.*?[a-z])/
      const special = /^(?=.*?[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/
      const number = /^(?=.*?[0-9])/

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= 8; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }

      const validationRules = {
        size: lengthRegex.test(password),
        upper: upper.test(password),
        lower: lower.test(password),
        special: special.test(password),
        number: number.test(password),
      }

      if (
        validationRules.size
        && validationRules.upper
        && validationRules.lower
        && validationRules.special
        && validationRules.number
      ) {
        this.user.password = password
      } else {
        this.generatePassword()
      }
    },
    handleCurrentPrivilege(value) {
      let privilege = ''
      switch (value) {
        case 'ROLE_USER':
          privilege = 'Utilisateur'
          return privilege
        case 'ROLE_ADMIN':
          privilege = 'Administrateur'
          return privilege
        case 'ROLE_SUPER_ADMIN':
          privilege = 'Super Administrateur'
          return privilege
        case 'ROLE_PREVISION_NAVIRE':
          privilege = 'Navire'
          return privilege
        case 'ROLE_DIR_COM':
          privilege = 'Actualites et Offres'
          return privilege
        case 'ROLE_OPERATION':
          privilege = 'Cotation'
          return privilege
        default:
          return privilege
      }
    },
    copyPassword() {
      navigator.clipboard.writeText(this.user.password)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Mot de passe',
          text: 'Mot de passe copié',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
    },
    handleDeleteModal(currentUser = {}) {
      this.currentUser = currentUser
      this.showDeleteModal = !this.showDeleteModal
    },
    openUpdateModal(currentUser = {}) {
      this.currentUser = currentUser
      this.phone = currentUser?.phone_number.substring(3)

      const currentRoles = []
      // eslint-disable-next-line array-callback-return
      this.userRoles.map(role => {
        currentUser.roles.forEach(element => {
          if (role.value === element) {
            currentRoles.push(role)
          }
        })
      })

      this.user = {
        username: currentUser?.username,
        phoneNumber: currentUser?.phone_number,
        email: currentUser?.email,
        roles: currentRoles,
        lastname: currentUser?.last_name,
        firstname: currentUser?.first_name,
        address: currentUser?.address,
        matricule: currentUser?.matricule,
        profession: currentUser?.profession,
        profileImage: '',
        password: null,
        callBackUrl: `${window.location.hostname}`,
      }
      this.showUpdateModal = true
    },
    closeUpdateModal(value) {
      this.currentUser = {}
      this.showUpdateModal = value
    },
    async getAllUsers() {
      this.loaded = true
      this.items = await this.$http
        .get(`/api/oauth/find-all-paged/${this.currentPage}/${this.perPage}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then(res => {
          this.loaded = false
          this.totalItems = res.data.data.total_count
          return res.data.data.items
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Liste des utilisateurs',
              text: 'Une erreur est survenue lors de la récupération.',
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    update(event) {
      this.isValid = event.isValid
      if (event.isValid) {
        this.user.phoneNumber = event.formattedNumber
      }
    },
    onOpenModal() {
      this.$emit('openModal', false)
    },
    onCloseModal() {
      this.$emit('closeModal', false)
    },
    formatter(value) {
      return value.toUpperCase()
    },
    lower(value) {
      return value.toLowerCase()
    },
    async checkEmail() {
      this.emailExist = null
      this.$v.user.email.$touch()
      if (this.$v.user.email.$error) return
      this.emailExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-email-exist?email=${this.user.email}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => 0)
    },
    async checkPhone() {
      this.phoneExist = null
      if (!this.isValid) return
      this.$v.user.phoneNumber.$touch()
      if (this.$v.user.phoneNumber.$error) return
      this.phoneExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-phone-exist?phoneNumber=${this.user.phoneNumber}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => false)
    },
    async checkUsername() {
      this.userExist = null
      if (!this.isValid) return
      this.$v.user.username.$touch()
      if (this.$v.user.username.$error) return
      this.userExist = await this.$http({
        method: 'post',
        url: `/api/oauth/check-username-exist?username=${this.user.username}`,
      })
        .then(res => {
          if (res.data.valid === 1) {
            return true
          }
          return false
        })
        .catch(() => false)
    },
    reset() {
      this.emailExist = null
      this.phoneExist = null
      this.userExist = null
      this.phone = null
      this.user = {
        username: null,
        phoneNumber: null,
        email: null,
        password: null,
        lastname: null,
        firstname: null,
        address: null,
        matricule: null,
        profession: null,
        profileImage: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    stepOne() {
      this.$v.user.lastname.$touch()
      this.$v.user.firstname.$touch()
      this.$v.user.email.$touch()
      this.$v.user.phoneNumber.$touch()
      this.$v.user.address.$touch()
      this.$v.user.profession.$touch()
      if (
        this.$v.user.lastname.$error
        || this.$v.user.firstname.$error
        || this.$v.user.email.$error
        || this.$v.user.phoneNumber.$error
        || this.$v.user.profession.$error
        || this.$v.user.address.$error
      ) { return }
      this.$refs.registerWizard.nextTab()
    },
    finalStep() {
      this.$v.user.username.$touch()
      this.$v.user.matricule.$touch()
      if (this.$v.user.username.$error || this.$v.user.matricule.$error) return
      this.tryToUpdateUser()
    },
    tryToUpdateUser() {
      const roles = [
        ...this.user.roles.map(element => element.value),
        'ROLE_USER',
      ]
      this.updateUser({
        username: this.user.username,
        phone_number: this.user.phoneNumber,
        email: this.user.email,
        password: this.user.password,
        lastname: this.user.lastname,
        firstname: this.user.firstname,
        address: this.user.address,
        matricule: this.user.matricule,
        profession: this.user.profession,
        profile_image: this.user.profileImage,
        call_back_url: `${window.location.protocol}://${window.location.hostname}`,
        roles,
      })
    },
    tryToDeleteUser() {
      this.deleteUser(this.currentUser.id.uid)
    },
  },
}
</script>
